import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import { Badge, Button, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import SystemStatus from '../../shared/components/SystemStatus'
import Info from './SystemDetail/Info'
import Meterings from './SystemDetail/Meterings'
import Invertors from './SystemDetail/Invertors'
import Map from './SystemDetail/Map'
import PowerRatio from '../../shared/components/PowerRatio'
import PowerValue from '../../shared/components/PowerValue'
import DateTime from '../../shared/components/DateTime'
import InvertorError from '../../shared/components/InvertorError'
import Restrictions from './SystemDetail/Restrictions'
import store from '../../shared/redux/store'
import { useDispatch } from 'react-redux'
import WeatherBlock from '../../shared/components/WeatherBlock'

import PowerOff from './SystemDetail/PowerOff'
import usePowerOffs from '../../shared/hooks/usePowerOff'
import useDataStream from '../../shared/hooks/useDataStream'
import useFetch from '../../shared/hooks/useFetch'

const SystemDetailView = (props) => {
    const systemID = props.match.params.system
    const identity = JSON.parse(window.localStorage.getItem('identity'))

    const { data: system } = useDataStream({
        url: `/api/v1/systems/${props.match.params.system}`,
        eventUrl: `/api/v1/systems/${props.match.params.system}/events`,
        identifier: 'system',
        type: `system-${props.match.params.system}`,
    })

    const { data: weather } = useFetch({ url: `/api/v1/weather/${systemID}` })
    const intl = useIntl()

    const [limitations, setLimitations] = useState(false)
    const [limited, setLimited] = useState(false)

    const fetchLimitations = useCallback(async () => {
        let dateFrom = moment().subtract(7, 'days').format()
        let dateTo = moment().add(7, 'days').format()
        let url =
            process.env.REACT_APP_API +
            '/api/v1/limitations?system=' +
            props.match.params.system +
            '&datefrom=' +
            dateFrom +
            '&dateto=' +
            dateTo +
            '&user=' +
            identity.login +
            '&password=' +
            identity.password

        await fetch(url, { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                setLimitations(data)
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }, [props.match.params.system, identity.login, identity.password])

    const [forecastCorrections, setForecastCorrections] = useState(false)
    const [, setForecastCorrected] = useState(false)
    const fetchForecastCorrections = useCallback(async () => {
        let dateFrom = moment().subtract(7, 'days').format()
        let dateTo = moment().add(7, 'days').format()
        let url =
            process.env.REACT_APP_API +
            '/api/v1/forecast-correction?system=' +
            props.match.params.system +
            '&datefrom=' +
            dateFrom +
            '&dateto=' +
            dateTo +
            '&user=' +
            identity.login +
            '&password=' +
            identity.password

        await fetch(url, { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                setForecastCorrections(data)
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }, [props.match.params.system, identity.login, identity.password])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            fetchLimitations()
            fetchForecastCorrections()
        }
        return () => {
            mounted = false
        }
    }, [fetchLimitations, fetchForecastCorrections])

    useEffect(() => {
        const activeLimitations = () =>
            limitations &&
            setLimited(
                limitations.filter(
                    (item) =>
                        moment().unix() >= moment(item.date_from).unix() &&
                        moment().unix() <= moment(item.date_to).unix()
                )
            )
        activeLimitations()
        const timer = setTimeout(() => {
            activeLimitations()
        }, 5000)
        return () => clearTimeout(timer)
    }, [limitations])

    useEffect(() => {
        const activeForecastCorrections = () =>
            forecastCorrections &&
            setForecastCorrected(
                forecastCorrections.filter(
                    (item) =>
                        moment().unix() >= moment(item.date_from).unix() &&
                        moment().unix() <= moment(item.date_to).unix()
                )
            )
        activeForecastCorrections()
        const timer = setTimeout(() => {
            activeForecastCorrections()
        }, 5000)
        return () => clearTimeout(timer)
    }, [forecastCorrections])
    const systemDetailActiveTab = store.getState().systemDetailActiveTab
    const [activeTab, setActiveTab] = useState(systemDetailActiveTab || 'info')

    const { powerOffs, fetchPowerOffs } = usePowerOffs()
    const componentTabs = [
        {
            name: 'info',
            label: <FormattedMessage id="info" defaultMessage="Info" />,
            component: (
                <Info
                    systemID={systemID}
                    maxPower={system && system.maxpower}
                    system={system && system}
                    weather={weather}
                />
            ),
        },
        {
            name: 'meterings',
            label: <FormattedMessage id="elektricitymeters" defaultMessage="Electricity meters" />,
            component: <Meterings identity={identity} systemID={systemID} />,
            disable: system && !system.features.metering,
        },

        {
            name: 'limitations',
            label: <FormattedMessage id="limitations" defaultMessage="Power limitations" />,
            component: (
                <Restrictions
                    fetchRestrictions={() => fetchLimitations()}
                    restrictions={limitations}
                    systemID={systemID}
                />
            ),
            disable: system && !system.features.limitation,
        },
        {
            name: 'poweroffs',
            label: <FormattedMessage id="powerOff.list" defaultMessage="PowerOff list" />,
            component: (
                <Restrictions
                    fetchRestrictions={() => fetchPowerOffs()}
                    restrictions={powerOffs}
                    systemID={systemID}
                    powerOff
                />
            ),
            disable: system && !system.features.limitation,
        },
        {
            name: 'forecastcorrections',
            label: <FormattedMessage id="forecast-correction" defaultMessage="Forecast corrections" />,
            component: (
                <Restrictions
                    fetchRestrictions={() => fetchForecastCorrections()}
                    restrictions={forecastCorrections}
                    forecastCorrection
                    systemID={systemID}
                />
            ),
            disable: system && !system.features.forecast,
        },
        {
            name: 'invertors',
            label: <FormattedMessage id="invertors.invertors" defaultMessage="Invertors" />,
            component: <Invertors activeTab={activeTab} systemID={systemID} />,
        },
        {
            name: 'map',
            label: <FormattedMessage id="map" defaultMessage="Map" />,
            component: <Map activeTab={activeTab} />,
        },
    ]
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: 'FETCH_DATA_SUCCESS', payload: { systemDetailActiveTab: activeTab } })
    }, [activeTab, dispatch])

    if (!system) return <Spinner variant="secondary" animation="border" />
    return (
        <div className="systemDetailView mx-3">
            <Row>
                <Col xs={12} md={6}>
                    <h1 className="h2">{system.description}</h1>
                </Col>
                <Col xs={12} md={6} className="text-md-right">
                    {system && system.fotobot_url !== '' && (
                        <a href={system.fotobot_url} className="mr-3 btn btn-outline-secondary">
                            {intl.formatMessage({ id: 'fotobot', defaultMessage: 'Fotobot' })}
                        </a>
                    )}
                    <PowerOff systemId={system.system} powerOffs={powerOffs} fetchPowerOffs={fetchPowerOffs} />
                    <Button as={Link} to="/system" variant="outline-primary" className="mr-3">
                        <FontAwesomeIcon
                            icon={faAngleDoubleLeft}
                            title={intl.formatMessage({ id: 'backto', defaultMessage: 'Back to' })}
                        />
                        &nbsp;
                        <FormattedMessage id="backto" defaultMessage="Back to" />{' '}
                        <FormattedMessage id="systems.systems" defaultMessage="Systems" />
                    </Button>
                    <Button as={Link} to="/groups" variant="outline-primary">
                        <FontAwesomeIcon
                            icon={faAngleDoubleLeft}
                            title={intl.formatMessage({ id: 'backto', defaultMessage: 'Back to' })}
                        />
                        &nbsp;
                        <FormattedMessage id="backto" defaultMessage="Back to" />{' '}
                        <FormattedMessage id="groups.groups" defaultMessage="Groups" />
                    </Button>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col xs={12} lg={1}>
                    <h6>
                        <FormattedMessage id="status" defaultMessage="Status" />
                    </h6>
                    {system && system.error !== undefined ? (
                        <React.Fragment>
                            <div className="d-flex align-items-center mb-1">
                                <SystemStatus error={system.error} />
                                <p className="ml-2 mb-0 weight-bold">
                                    <InvertorError error={system.error} />
                                </p>
                            </div>
                            {limited.length > 0 && (
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon
                                        className="text-warning"
                                        icon={faExclamationTriangle}
                                        title={intl.formatMessage({
                                            id: 'limitations.limited',
                                            defaultMessage: 'Limited',
                                        })}
                                    />
                                    <p className="ml-2 mb-0 weight-bold">
                                        <FormattedMessage id="limitations.limited" defaultMessage="Limited" />
                                        <Badge variant="warning" className="flex-grow ml-2 mt-1">
                                            {limited[0].percent}%
                                        </Badge>
                                    </p>
                                </div>
                            )}
                            {/* forecastCorrected.length > 0 && (
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon
                                        className="text-warning"
                                        icon={faExclamationTriangle}
                                        title={intl.formatMessage({
                                            id: 'limitations.limited',
                                            defaultMessage: 'Forecast corrected',
                                        })}
                                    />
                                    <p className="ml-2 mb-0 weight-bold">
                                        <FormattedMessage
                                            id="limitations.forecastCorrected"
                                            defaultMessage="Forecast correction"
                                        />
                                        <Badge variant="warning" className="flex-grow ml-2 mt-1">
                                            {forecastCorrected[0].percent}%
                                        </Badge>
                                    </p>
                                </div>
                                    ) */}
                        </React.Fragment>
                    ) : (
                        <Spinner animation="border" role="status" variant="info">
                            <span className="sr-only">
                                <FormattedMessage id="loading" defaultMessage="Loading&hellip;" />
                            </span>
                        </Spinner>
                    )}
                </Col>
                <Col xs={12} lg={3}>
                    <h6 className="mb-3">
                        <FormattedMessage id="powerratio" defaultMessage="Power Ratio" />
                    </h6>
                    {system && <PowerRatio power={system.power} maxpower={system.maxpower} />}
                </Col>
                <Col xs={12} lg={2}>
                    <h6>
                        <FormattedMessage id="currentpower" defaultMessage="Current Power" />
                    </h6>
                    {system ? (
                        <b>
                            <PowerValue value={system.power} />
                        </b>
                    ) : (
                        <b>
                            <PowerValue value={0} />
                        </b>
                    )}
                </Col>
                <Col xs={6} lg={3}>
                    <h6>
                        <FormattedMessage id="lastupdate" defaultMessage="Last update" />{' '}
                    </h6>
                    <b>{system && <DateTime data={system.last_know_data} inline />}</b>
                </Col>
                {weather && weather.conditions && (
                    <Col xs={6} lg={3}>
                        <h6>
                            <FormattedMessage id="weather-forecast.weather" defaultMessage="Weather" />
                        </h6>
                        <WeatherBlock weather={weather.conditions} box />
                    </Col>
                )}
            </Row>

            <Tab.Container activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                <Nav variant="tabs" className="m-0 border-bottom mt-4">
                    {componentTabs.map(
                        (tab) =>
                            !tab.disable && (
                                <Nav.Item key={tab.name}>
                                    <Nav.Link eventKey={tab.name}>{tab.label}</Nav.Link>
                                </Nav.Item>
                            )
                    )}
                </Nav>
                <Tab.Content className="p-3 mb-5">
                    {componentTabs.map(
                        (tab) =>
                            !tab.disable && (
                                <Tab.Pane key={tab.name} eventKey={tab.name}>
                                    {tab.component}
                                </Tab.Pane>
                            )
                    )}
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default SystemDetailView

// kate: replace-tabs on; tab-width 4; show-tabs on;
