import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import fetchData from '../lib/fetchData'
import getApiUrl from '../lib/getApiUrl'

export default function usePowerOffs() {
    const [powerOffs, setPowerOffs] = useState(null)
    const [loading, setLoading] = useState(false)

    const fetchPowerOffs = useCallback(() => {
        fetchData({
            url: '/api/v1/poweroff',
            setData: setPowerOffs,
            setLoading,
        })
    }, [])
    useEffect(() => {
        fetchPowerOffs()
    }, [fetchPowerOffs])

    const setPowerOff = async (system, date_from, date_to) => {
        const res = await fetch(getApiUrl(`/api/v1/poweroff`), {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ system, date_from, date_to }),
        })
        const json = await res.json()
        console.log(json)
    }

    const deletePowerOff = async (powerOff) => {
        if (moment(powerOff?.date_from).unix() <= moment().unix()) {
            const res = await fetch(getApiUrl(`/api/v1/poweroff/${powerOff.poweroff}`), {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({ date_to: moment(moment(), 'D.M.YYYY HH:mm').format() }),
            })
            const json = await res.json()
            console.log(json)
        } else {
            const res = await fetch(getApiUrl(`/api/v1/poweroff/${powerOff.poweroff}`), {
                method: 'DELETE',
                credentials: 'include',
            })
            const json = await res.json()
            console.log(json)
        }

        fetchPowerOffs()
    }

    return { powerOffs, loading, fetchPowerOffs, setPowerOff, deletePowerOff }
}
